<template>
  <div class="useModal">
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="568px"
      :before-close="handleClose"
    >
      <div class="content">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-form-item label="签约主体" prop="applySort">
            <el-select v-model="ruleForm.applySort" placeholder="请选择签约主体：企业或个人" @change="onChangeSort">
              <el-option
                  v-for="item in applySortOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公司名称" prop="applyCompanyName" v-if="companyShow">
            <el-input
              v-model="ruleForm.applyCompanyName"
              placeholder="请输入公司名称"
              :disabled="companyDisable"
            ></el-input>
          </el-form-item>

          <el-form-item label="省份/地市" prop="city">
            <v-distpicker
                hide-area
                @change-province="onChangeProvince"
                @change-city="onChangeCity"
                :placeholders="placeholders"
            />
          </el-form-item>

          <el-form-item label="申请人" prop="applyUserName">
            <el-input
              v-model="ruleForm.applyUserName"
              placeholder="请输入申请人"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="applyPhone">
            <el-input
              v-model="ruleForm.applyPhone"
              placeholder="请输入手机号"
              :disabled="disabled"
            ></el-input>
          </el-form-item>
          <el-form-item label="备选手机号" prop="applyExplain">
            <el-input
              v-model="ruleForm.applyExplain"
              placeholder="请输入备选手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱" prop="applyEmail">
            <el-input
              v-model="ruleForm.applyEmail"
              placeholder="请输入电子邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item label="信息来源" prop="informationSource">
            <!--            <el-input v-model="ruleForm.informationSource" placeholder="请填写邮件 | 抖音 | 短信 | 海报 | 其他"></el-input>-->
            <el-select
              v-model="ruleForm.informationSource"
              placeholder="请选择信息来源"
            >
              <el-option
                v-for="item in informationSourceOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公司类型" prop="companyType" v-if="companyShow">
            <el-select
              v-model="ruleForm.companyType"
              placeholder="请选择信息来源"
            >
              <el-option
                v-for="item in companyTypeOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="labelInfo" prop="remarks" v-if="companyShow">
            <el-input
              raws="5"
              type="textarea"
              v-model="ruleForm.remarks"
              :placeholder="'请输入' + labelInfo"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="btn-box">
        <!-- <div class="btn" @click="applyBtn">{{title}}</div> -->
        <el-button
          class="btn"
          type="primary"
          @click="applyBtn"
          :loading="loading"
          >{{ title }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Form, FormItem, Input, Select, Option } from "element-ui";
import { applyContract } from "../../api/product";
export default {
  name: "useModal",
  components: {
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      placeholders: {
        province: '请选择省份',
        city: '请选择地市',
      },
      loading: false,
      dialogVisible: false,
      disabled: false,
      title: "",
      labelInfo: "",
      userInfo: {},
      companyDisable:false,
      companyShow: true,
      ruleForm: {
        applySort:"",
        applyCompanyName: "",
        province: "",
        city: "",
        applyUserName: "",
        applyPhone: "",
        applyExplain: "",
        applyEmail: "",
        informationSource: "",
        remarks: "",
        applyType: null,
        companyType: "",
        productId: this.$route.params.id,
      },
      informationSourceOption: [
          /*旧的来源信息*/
        /*{ value: "订阅号", label: "订阅号" },
        { value: "海报", label: "海报" },
        { value: "科技日报", label: "科技日报" },
        { value: "头条", label: "头条" },
        { value: "微博", label: "微博" },
        { value: "抖音", label: "抖音" },
        /!*{value: 'Q医疗', label: 'Q医疗'},
        {value: '医盟网', label: '医盟网'},*!/
        { value: "短信", label: "短信" },
        { value: "邮件", label: "邮件" },
        { value: "知乎", label: "知乎" },
        { value: "一条", label: "一条" },
        { value: "B站", label: "B站" },
        { value: "CSDN", label: "CSDN" },
        { value: "搜狐", label: "搜狐" },
        { value: "百家号", label: "百家号" },
        { value: "健康界", label: "健康界" },
        { value: "连线CIO", label: "连线CIO" },
        { value: "动脉网", label: "动脉网" },
        { value: "其他", label: "其他" },*/

        { value: "搜索引擎", label: "搜索引擎" },
        { value: "微信公众号", label: "微信公众号" },
        { value: "开源中国", label: "开源中国" },
        { value: "gitee", label: "gitee" },
        { value: "CSDN", label: "CSDN" },
        { value: "GitHub", label: "GitHub" },
        { value: "新致新源自媒体（微博/知乎/百家号/头条号等）", label: "新致新源自媒体（微博/知乎/百家号/头条号等）" },
        { value: "媒体新闻报道", label: "媒体新闻报道" },
        { value: "线下活动", label: "线下活动" },
        { value: "他人推荐", label: "他人推荐" },
        { value: "其它", label: "其它" },
      ],
      companyTypeOption: [
        /*旧的公司类型*/
        /*{ value: "医软自研", label: "医软自研" },
        { value: "软件公司", label: "软件公司" },
        { value: "医软集成", label: "医软集成" },
        { value: "医疗器械", label: "医疗器械" },
        { value: "医药公司", label: "医药公司" },
        { value: "医疗机构", label: "医疗机构" },
        { value: "中小企业", label: "中小企业" },
        { value: "网络安全", label: "网络安全" },
        { value: "大专院校", label: "大专院校" },
        { value: "金融公司", label: "金融公司" },
        { value: "教育公司", label: "教育公司" },
        { value: "研究院", label: "研究院" },*/

        { value: "软件自研", label: "软件自研" },
        { value: "软件集成", label: "软件集成" },
        { value: "财务代理", label: "财务代理" },
        { value: "广告营销", label: "广告营销" },
        { value: "IT互联网/电商", label: "IT互联网/电商" },
        { value: "商业咨询/中介服务", label: "商业咨询/中介服务" },
        { value: "快消/零售/餐饮/住宿", label: "快消/零售/餐饮/住宿" },
        { value: "通信/房地产", label: "通信/房地产" },
        { value: "制造业", label: "制造业" },
        { value: "金融", label: "金融" },
        { value: "教育培训", label: "教育培训" },
        { value: "医疗卫生", label: "医疗卫生" },
        { value: "文化/体育/娱乐", label: "文化/体育/娱乐" },
        { value: "农林牧渔", label: "农林牧渔" },
        { value: "高校/研究院", label: "高校/研究院" },
        { value: "行业协会", label: "行业协会" },
        { value: "政府机构/公共事业", label: "政府机构/公共事业" },
        { value: "其它", label: "其它" },
      ],
      applySortOption: [
        { value: "企业", label: "企业" },
        { value: "个人", label: "个人" },
      ],
      rules: {
        applySort: [
          { required: true, message: "请选择签约主体", trigger: "blur" },
        ],
        applyCompanyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        province: [{ required: true, message: "请选择省份", trigger: "blur" }],
        city: [{ required: true, message: "请选择省市", trigger: "blur" }],
        applyUserName: [
          { required: true, message: "请输入申请人", trigger: "blur" },
        ],
        applyPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        applyExplain: [
          { required: false, message: "请输入备选手机号", trigger: "blur" },
        ],
        applyEmail: [
          { required: true, message: "请输入电子邮箱", trigger: "blur" },
        ],
        informationSource: [
          { required: true, message: "请选择信息来源", trigger: "blur" },
        ],
        companyType: [
          { required: true, message: "请选择公司类型", trigger: "blur" },
        ],
        remarks: [
          {
            required: true,
            message: "请输入" + this.labelInfo,
            trigger: "blur",
          },
        ],
      },
    };
  },

  mounted() {
    if (this.type === "1") {
      this.title = "申请演示";
      this.labelInfo = "申请演示原因";
      this.ruleForm.applyType = 1;
    } else if (this.type === "2") {
      this.title = "立即签约";
      this.labelInfo = "企业简介";
      this.ruleForm.applyType = 0;

      // this.userInfo = JSON.parse(localStorage.getItem('userInfo')) || {}
      // if(this.userInfo && this.userInfo.mobile) {
      //   this.ruleForm.applyPhone = this.userInfo.mobile
      //   this.disabled = true
      // } else {
      //   this.ruleForm.applyPhone = ''
      //   this.disabled = false
      // }
    } else {
      this.title = "加入社区";
      this.labelInfo = "企业简介";
      this.ruleForm.applyType = 2;
    }
  },
  methods: {
    showDialog() {
      this.dialogVisible = true;
      // console.log(this.$store.state.user.userInfo.mobile, '9090')
      this.userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
      if (
        this.$store.state.user.userInfo.mobile ||
        (this.userInfo && this.userInfo.mobile)
      ) {
        this.ruleForm.applyPhone =
          this.$store.state.user.userInfo.mobile || this.userInfo.mobile;
        this.disabled = true;
      } else {
        this.ruleForm.applyPhone = "";
        this.disabled = false;
      }
    },

    handleClose() {
      this.dialogVisible = false;
    },

    onChangeProvince(data) {
      console.log(data);
      this.ruleForm.province = data.value;
    },

    onChangeCity(data) {
      console.log(data);
      this.ruleForm.city = data.value;
    },

    onChangeSort(data) {
      console.log(data);
      this.ruleForm.applySort = data;
      if(data === "个人"){
        this.companyDisable = true;
        this.companyShow = false;
        /*let that = this;
        setTimeout(function () {
          that.applyBtn();
        }, 500);*/
        this.$refs.ruleForm.clearValidate();
      }else if(data === "企业"){
        this.companyDisable = false;
        this.companyShow = true;
        /*let that = this;
        setTimeout(function () {
          that.applyBtn();
        }, 500);*/
        this.$refs.ruleForm.clearValidate();
      }
    },

    applyBtn() {
      if(this.ruleForm.province === '请选择省份'){
        this.ruleForm.province='';
      }
      if(this.ruleForm.city === '请选择地市'){
        this.ruleForm.city='';
      }

      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          applyContract(this.ruleForm)
            .then((res) => {
              this.loading = false;
              if (res.code === 200) {
                this.dialogVisible = false;
                this.$emit("applyContractSuccess", true);
                this.$message.success("签约成功");
                this.$refs.ruleForm.resetFields();
              } else {
                this.$message.error(res.message);
              }
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-dialog__title {
  color: $colorT1;
  font-weight: 600;
}
/deep/ .el-select {
  width: 100%;
}
.el-checkbox {
  margin-left: 85px;
}
.useModal {
  .btn-box {
    text-align: right;
    .btn {
      background-color: $colorA;
      border: 1px solid $colorA;
    }
  }
}
@media only screen and (max-width: 800px) {
  /deep/.el-dialog {
    width: 100% !important;
    height: auto;
  }
  /deep/ .v-modal {
    z-index: 20;
  }
  .wx-login {
    display: none;
  }
  .card {
    padding: 0;
  }
}
</style>
