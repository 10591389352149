<template>
  <div class="contractModal">
    <el-dialog
        ref="contract-modal"
        class="contract-modal"
        id="el-dialog__wrapper"
        title="新致开源软件许可协议"
        width="846px"
        :visible.sync="dialogVisible"
        :before-close="handleClose">
      <div>
        <p class="updateTime">更新日期：2024年4月19日</p>

        <p class="indent mb-8 title">下载或使用本协议随附的软件前，请您仔细阅读下述条款和条件。使用软件或点击“确认签约”按钮或类似的按钮，即表示您同意仅在遵循下述条款和条件的前提下使用本协议随附的软件。</p>
        <p class="mb-16 indent">本新致开源软件免费许可协议（下称“本协议”）系为您与武汉新致新源软件有限公司（以下称“新致新源 ”、“我们”、“我们的”和“本公司”）之间具有法律约束力的合同，并约束您对新致开源软件（定义如下）的使用。</p>
        <p class="mb-16 indent title" >“新致开源软件”，系指由新致新源提供的开源软件，包括开源医疗发行版（含院版HIS、诊所版HIS、互联网医院三类产品）、开源企业发行版（含人事管理、财务管理、客户管理、项目管理四类产品）以及相关软件的更新，以及本协议第 1 条所述的新致开源软件的所有版本。</p>
        <p class="mb-16 indent">通过安装或以其他方式使用新致开源软件或点击“确认签约” 按钮，即表示您同意本协议的条款并同意于安装、使用 新致开源软件之日或点击“确认签约” 按钮之日（以孰早者为准）受本协议约束。</p>
        <p class="mb-16 indent">您保证您至少年满十八周岁，并且您拥有签署合同的法定能力。如果您代表任何公司、组织或其他实体签署本协议，则（1）本协议中所称“您”同时亦指该公司、组织、实体或其关联方；（2）您声明并保证您拥有约束该公司、组织、实体或其关联方接受本协议所规定条款和条件的许可；（3）您无需取得其他第三方许可方可签署并履行本协议；并且（4）签署和履行本协议不会违反您已签署的其他协议或适用的法律法规。如果您不同意本协议的任何条款或尚未取得许可签署本协议，则必须立即停止使用新致开源软件，取消并删除所有副本。</p>
        <p class="mb-16 indent"><span class="mb-16 title">1、协议的适用版本。</span>如果您已按照任何其他许可条款安装或使用了新致开源软件的任何先前版本，则通过安装或使用此协议随附的软件版本，即表示您同意本协议将全部替换那些其他许可条款并且还将管辖您对新致开源软件的所有先前版本的使用。</p>
        <p class="mb-16 indent"><span class="mb-16 title">2、期限和终止。</span>本协议将持续适用，直至（1）您随时通过卸载和删除您下载或控制的新致开源软件的所有副本来终止本协议；或（2）如因您使用新致开源软件过程中出现违反本协议的情况，新致新源将通知您终止本协议并取消您的许可，您必须卸载并删除您拥有或控制的新致开源软件的所有副本。</p>
        <p class="mb-16 indent"><span class="mb-16 title">3、协议的修改。</span>如新致新源需要对本协议进行变更或修改的，新致新源会通过本公司“开源医疗”网站（openhis.org.cn）及“开源企业”网站（opencom.com.cn）公告的方式提前予以公布；若您在本协议内容公告变更后继续使用新致开源软件的，表示您已充分阅读、理解并接受变更后的协议内容，也将遵循变更后的协议内容。新致开源也可能会不时通过新致开源软件的更新版本通知您的方式修改本协议或以新条款替换本协议。</p>
        <p class="mb-16 indent"><span class="mb-16 title">4、免费许可授予。</span>新致开源社区软件是许可给您的，而非出售给您的。在本协议有效期内，并且在您遵守本协议的前提下，<span class="title">新致新源授予您非排他性、不可转让、不可分配、不可再许可、可撤消的许可，针对您个人或团队内部学习、教学、研究相关目的，许可您免费安装及使用新致开源软件，</span>除上述明确约定的适用许可外，新致新源保留与新致开源软件相关的全部知识产权。此外，您必须要遵守本协议第 5 条中的许可限制，并确保您的产品用户也遵守本协议第 5 条中的许可限制。</p>
        <p class="mb-16 indent"><span class="mb-16 title">5、免费许可限制。</span>除了本协议中的其他限制和限制外，您不能，也不得协助或许可他人：（1）解决或绕过新致开源软件中的任何技术限制，这些技术限制仅允许您以某些方式使用新致开源软件；（2）违法使用新致开源软件，包括但不限于违反任何出口或再出口法规或法律将新致开源软件转移到任何国家，或侵犯，挪用或以其他方式侵犯任何第三方的任何权利；（3）禁止利用新致开源软件发表、传送、传播、储存违反国家法律、危害国家安全、社会稳定、公序良俗的内容，或任何不当的、侮辱诽谤的、淫秽的、暴力的及任何违反国家法律法规政策的内容。</p>
        <p class="mb-16 indent">除了上述限制以外，<span class="title">未经新致新源正式商用许可，您不能，也不得协助或许可他人：（4）独立出售、许可、分发、提供新致开源软件；（5）直接共享、商品化、出售、转让、许可或再许可新致开源软件；（6）基于新致开源软件提供任何形式的商业技术服务。</span></p>
        <p class="mb-16 indent"><span class="mb-16 title">6、使用验证。</span>新致新源可能会验证您是否在遵守本协议的情况下使用新致开源软件，包括但不限于通过新致开源软件中可能向新致新源传输与您使用新致开源软件有关的数据的技术功能。根据新致新源的要求，您将向新致新源提供系统生成的信息，以验证您对新致开源软件的使用符合本协议。如果发现您违反本协议使用新致开源软件，您必须立即向新致新源支付与使用新致开源软件有关的许可费，该费用等于假设您已获得新致开源软件商用许可时需要向新致新源支付的金额。同时新致新源保留追究因您未经许可使用新致开源软件的一切法律权利。</p>
        <p class="mb-16 indent"><span class="mb-16 title">7、隐私和数据安全。</span>根据本协议，新致新源对新致新源收集的任何用户数据（如有）的使用均受新致新源隐私政策的约束，该隐私政策详见本公司“开源医疗”网站（openhis.org.cn）及“开源企业”网站（opencom.com.cn）公告，您对新致开源软件的使用表示同意新致新源隐私政策中所述的做法。</p>
        <p class="mb-16 indent"><span class="mb-16 title">8、商业服务和技术支持。</span>您在此知悉确认，您在线获取的新致开源软件系为免费版本，不包括相关的商业服务和技术支持。尽管新致新源组织了用户社区，并组织新致开源软件用户就新致开源软件进行了交流，但新致新源不承担任何组织用户交流新致开源软件的义务与责任。新致新源在用户社区中对涉及新致开源软件技术开展讨论交流，均不承担潜在的风险和实际可能导致某一方系统故障所产生的有形或无形的经济损失及法律责任。新致新源没有义务向您提供或持续提供新致开源软件（包括任何更新、升级或新版本），也没有义务承担因新致开源软件可能产生的任何损失和责任。<span class="title">如需商业服务和技术支持，您需要与我们另行签订商务合作协议，具体服务内容以另行签订的商务合作协议为准。</span></p>
        <p class="mb-16 indent"><span class="mb-16 title">9、免责声明。</span>在适用法律允许的最大范围内：（1）您必须自行承担使用任何新致开源软件的风险，而对质量、性能和准确性由您独自承担；（2）您在网站上实际下载产品版本为新致新源实际授予您的许可产品，新致新源不作任何包括但不限于对无故障、适销性、质量满意度，对特定目的的适用性、准确性、可用性的保证和承诺，新致新源提供的任何信息或建议均不构成任何担保；（3）用户因使用新致开源软件违反国家法律法规的，新致新源公司不承担任何责任。</p>
        <p class="mb-16 indent"><span class="mb-16 title">10、责任范围。</span>在适用法律允许的最大范围内：在任何情况下，新致新源均不对任何直接的、附带的、特殊的、间接的或后果性的损失或利润损失、数据损失、商誉损失、业务中断或任何其他形式的损失负责，对由您的使用或无法使用新致开源软件引起或与之相关的其他损害或损失概不负责。</p>
        <p class="mb-16 indent"><span class="mb-16 title">11、不转让。</span>未经新致新源的书面同意，您不得全部或部分转让或以其他方式转让您在本协议下的权利和义务，任何此类尝试均将无效。新致新源可能会无需经过您的同意，在发生并购、合并或控制权变更的情况下将本协议权属转让给相关承继者或受让方。</p>
        <p class="mb-16 indent"><span class="mb-16 title">12、法律适用与争议解决。</span>本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。您同意本协议签订地为中国上海市浦东新区。在合同履行过程中双方如发生纠纷，应协商解决；协商不成的，双方应向协议签订地有管辖权的人民法院以诉讼的方式解决。</p>
        <p class="mb-16 indent title" style="color: red">【温馨提示】您在线签署的是免费许可协议，适用于个人级团队学习、教学、研究相关目的。如您的企业需要商用（独立出售、许可、分发或自用等），请联系客服进一步沟通获取商用许可。具体请查看<a style="color: red;border-bottom: 3px solid;" href="http://openhis.org.cn/#/new/cooperation" target="_blank">合作方式</a>。</p>

        <!--        <p>法人或授权代表：</p>-->
        <!--        <p>联系人及手机号：</p>-->
        <!--        <p>乙方：武汉新致数字科技有限公司</p>-->
        <!--        <p>法人或授权代表：</p>-->
        <!--        <p class="mb-16">联系人及手机号：</p>-->
<!--        <p class="indent mb-8">新致开源企业项目商业合作协议（下称“本协议”）包含您在经销新致开源企业平台的商品或服务时所应遵守的条款和条件。本协议由武汉新致数字科技有限公司（下称“本公司”或“我们”）与您或您所代表的实体（下称“用户”或“您”）签订。如果您代表某一实体签订本协议，您向我们声明并保证，您具有合法代表该实体之权限且有可约束该实体遵守本协议条款与条件之合法权限。除非我们另有通知，本协议自您接受之日起生效。您与本我们在下文中单独提及时会被称作一方，一同提及时会被称作双方。</p>
        <p class="mb-16 indent">根据《中华人民共和国民法典》的有关规定，经双方友好协商，本着长期平等合作，互利互惠的原则，为实现“新致开源企业产品（COM）商业发行版”（以下简称“发行版”）的商业推广和联合运营，创造良好的经济效益和社会效益，达成以下协议：</p>
        <p class="title">一、合作宗旨</p>
        <p class="mb-16 indent">促进国内企业数字化的发展，利用您广泛的市场资源优势，发挥我们企业服务软件的研发能力，合作实现发行版的技术升级与市场营运。</p>
        <p class="title">二、合作范围</p>
        <p class="mb-8">1、市场推广</p>
        <p>1.1、双方共同对发行版进行推广、销售，包括但不限于直接或间接参加企服行业的招投标、代理招募和中小规模公司机构推广；</p>
        <p class="mb-8">1.2、双方共同对发行版进行品牌力提升，包括但不限于参加国内各类重大企服行业大会。</p>
        <p class="mb-8">2、产品研发</p>
        <p>2.1、推动发行版和其他系统进行兼容、集成；</p>
        <p class="mb-16">2.2、对发行版进行迭代开发，遵循GPL开源协议（《GNU GENERAL PUBLIC LICENSE》）。</p>
        <p class="title">三、合作方式</p>
        <p class="mb-8">1、您以现有的市场营销网络及社会资源为基础，进一步的开发市场潜力，逐步形成一个规范化且有区域影响力的营销网络；</p>
        <p class="mb-8">2、您根据市场客群需求，推广发行版，收集反馈用户需求；</p>
        <p class="mb-16">3、我们通过天天数链平台（www.tntlinking.com）提供经过确权的发行版源代码及相关资料库。</p>
        <p class="title">四、权力及义务</p>
        <p class="mb-8">1、您的义务</p>
        <p>1.1、您承诺并同意在发行版合作期内，必须遵守国家相关法律、正规合法运营及缴纳税收，如发生任何违法违纪行为所产生的一切法律责任及赔偿均由您独立承担；</p>
        <p>1.2、您严格在我们许可下使用发行版，需在系统中保留“武汉新致数字科技有限公司@2017开源授权”的字样；</p>
        <p class="mb-8">1.3、根据商业化推广要求，您在与客户完成签约后10日之内，向我们报备客户相关信息，提供签章合同扫描件的关键页。</p>
        <p class="mb-8">2、您的权利</p>
        <p>2.1、我们授权您对发行版进行销售、修改，修改过程中需遵守本协议第四条4.1.2款约定；</p>
        <p class="mb-8">2.2、您在发行版销售过程中，有权自主决定目标客户、销售价格、服务条款等相关内容。</p>
        <p class="mb-8">3、我们的义务</p>
        <p>3.1、提供给您的发行版拥有原始且完整的著作权；</p>
        <p>3.2、对发行版产品进行持续研发和升级；</p>
        <p class="mb-8">3.3、在合作过程中，对涉及到您的有关机密数据进行严格保密。</p>
        <p class="mb-8">4、我们的权利</p>
        <p>4.1、我们对您推广发行版的市场情况具有知情权；</p>
        <p>4.2、我们对您使用发行版运营收益情况具有知情权；</p>
        <p class="mb-16">4.3、我们可书面询问您上述两款的情况，您应于5个工作日内进行答复并对我们提出的疑义提供材料证明。</p>
        <p class="title">五、利益分配</p>
        <p class="mb-8">1、分配规则</p>
        <p>1.1、若报价以产品加实施的方式成交，则我们收取产品成交价的40%；</p>
        <p class="mb-16">1.2、除上述5.1.1所述的报价方式外成交的，我们收取项目成交价的15%。</p>
        <p class="mb-8">2、支付方式</p>
        <p>2.1、您应在收到客户第一笔款后的7日内告知我们，在收到我们按照同比例额度开具的增值税专用发票（税率：6%）后30日内支付给我们；</p>
        <p>2.2、您应在收到客户最后一笔款后的7日内告知我们，在收到我们按照剩余额度开具的增值税专用发票（税率：6%）后30日内支付给我们；</p>
        <p class="mb-16">2.3、我们须保证所提供发票的真实有效合法性。您逾期告知或逾期付款的，每逾期一天，应按逾期款的1%（百分之一）向我们支付违约金，直至支付完毕之日止。逾期若达30日，我们有权单方面解除本协议。</p>-->
        <!--        <p>3、账户信息</p>-->
        <!--        <p>3.1、甲方信息</p>-->
        <!--        <p>公司名称：【】</p>-->
        <!--        <p>统一社会信用代码：【】</p>-->
        <!--        <p>户名：【】</p>-->
        <!--        <p>开户行：【】</p>-->
        <!--        <p>帐号：【】</p>-->
        <!--        <p class="mb-16">地址电话：【】</p>-->
        <!--        <p>3.2、乙方信息</p>-->
        <!--        <p>公司名称：【武汉新致数字科技有限公司】</p>-->
        <!--        <p>统一社会信用代码：【91420100MA4KULGR6H】</p>-->
        <!--        <p>户名：【武汉新致数字科技有限公司】</p>-->
        <!--        <p>开户行：【招商银行武汉光谷支行】</p>-->
        <!--        <p>帐号：【127910237210902】</p>-->
        <!--        <p class="mb-16">地址电话：【武汉东湖新技术开发区花城大道8号武汉软件新城二期一组团B10栋4层01号 027-87003176】</p>-->

        <!--        <p class="title">六、保密条款</p>-->
        <!--        <p>1、甲乙双方所提供给对方的一切资料，专项技术和对项目的策划设计需严格保密，且只能在合作双方公司的业务范围内使用；</p>-->
        <!--        <p>2、凡涉及由甲乙双方提供与项目，资金有关的所有材料，包括但不限于资本营运计划，财资情报，客户名单，经营决策，项目设计，资本融资，技术数据，项目商业计划书等均属保密内容；</p>-->
        <!--        <p>3、凡未经双方书面同意以直接、间接、口头或者书面的形式向第三方提供涉及保密内容的行为均属泄密；</p>-->
        <!--        <p class="mb-16">4、如任何一方由于违背保密条款从而导致对方经济利益受损，则需要赔偿所有损失。</p>-->

        <!--        <p class="title">七、双方约束</p>-->
        <!--        <p>1、甲乙双方在合作期内，任何一方由于自身某种原因，导致无法继续履行合同时应提前1个月通知对方，同时采取措施保证发行版运营不受影响。</p>-->
        <!--        <p class="mb-16">2、本协议有效期为贰年，自签署当日生效。</p>-->

        <!--        <p class="title">八、违约条款</p>-->
        <!--        <p>1、甲方违约责任</p>-->
        <!--        <p>1.1、甲方违反本协议第四条1.2款（特殊原因除外），一经发现，乙方有权单方面停止合作并收回发行版的开源授权；</p>-->
        <!--        <p>1.2、甲方违反本协议第四条1.3款，一经发现，甲方需在30日内向乙方支付2倍项目金额的违约金。</p>-->
        <!--        <p>2、乙方违约责任</p>-->
        <!--        <p class="mb-16">乙方若违反本协议第四条3、乙方义务，导致甲方项目出现损失的，乙方需在30日内向甲方支付2倍项目损失的违约金。</p>-->

<!--        <p class="title">六、其它</p>
        <p class="mb-8">1、适用法律及管辖</p>
        <p class="mb-16">本协议适用法律为中华人民共和国法律。您和平台同意，本协议签订地为中国上海市浦东新区。在合同履行过程中双方如发生纠纷，应协商解决；协商不成的，双方应向协议签订地有管辖权的人民法院以诉讼的方式解决。</p>
        <p class="mb-8">2、声明</p>
        <p class="mb-16">您在使用本公司提供的签约下载服务之前，请您务必审慎阅读、充分理解本协议各条款内容，包括但不限于免除或者限制责任的条款。如您不同意本服务协议及/或随时对其的修改，您可以主动停止使用新致公司提供的签约下载服务；您一旦使用本公司提供的签约下载服务，即视为您已了解并完全同意本服务协议各项内容，包括本公司对服务协议随时所做的任何修改，并成为我们的用户。</p>-->
        <!--        <p>1、甲乙双方在执行本合同时发生争议，可通过双方友好协商解决，若经双方调解无效，可向上海仲裁委员会提请仲裁解决。</p>-->
        <!--        <p>2、本协议未尽事宜，双方协议订补充协议，与本协议同样具有法律效应。</p>-->
        <!--        <p>3、本协议一式贰份，双方各执壹份。</p>-->
        <!--        <p>4、本协议经双方签章（含电子签章方式）生效。</p>-->
        <!--        <p class="mb-16">（以下无正文，为甲乙双方协议签署页）</p>-->
        <!--        <p>甲方：</p>-->
        <!--        <p>联系地址：</p>-->
        <!--        <p>法人或授权代表：</p>-->
        <!--        <p class="mb-16">甲方签署时间：</p>-->
        <!--        <p>乙方：	武汉新致数字科技有限公司</p>-->
        <!--        <p>联系地址：武汉东湖新技术开发区花城大道8号武汉软件新城二期一组团B10栋4层01号</p>-->
        <!--        <p>法人或授权代表：</p>-->
        <!--        <p>乙方签署时间：</p>-->
      </div>

      <el-checkbox v-model="checked">我已阅读并同意本协议</el-checkbox>

      <div class="btn-box" ref="btnBox">
        <el-button class="cancel-btn btn" @click="closeContranct">取 消</el-button>
        <el-button class="contract-btn btn" :disabled="disabled" @click="contractClick">{{buttonInfo}}</el-button>
      </div>
    </el-dialog>

    <use-modal ref="useModal" type="2" @applyContractSuccess="applyContractSuccess"></use-modal>

  </div>
</template>

<script>
import {Dialog, Button, Checkbox} from 'element-ui'
import UseModal from "./useModal";
export default {
  name: "contractModal",
  components: {
    UseModal,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      checked: false,
      dialogVisible: false,
      disabled: true,
      timer: null,
      time:10,
      count: 0,
      buttonInfo:'确认签约10S',
      offsetTop: 0,
      isMobile: false,
    }
  },
  mounted() {
    this.$Bus.$on('closeModal', (res => {
      if(res) {
        this.handleClose()
      }
    }))

    this.isMobile = this.getIsMobile()
    // console.log(this.isMobile)
    // this.isMobile = window.matchMedia("(max-width: 600px)").matches;
    // console.log(this.isMobile)

    if(this.isMobile) {
      // window.addEventListener("touchstart", this.handleTouchstart, false);
    }

    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    getIsMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    },

    applyContractSuccess(flag) {
      flag ? this.dialogVisible = false : ''
    },

    handleTouchstart() {
      this.$nextTick( ()=> {
        // let offsetTop = document.getElementsByClassName('el-dialog__body')[0].offsetTop
        if (this.count === 0) {
          this.count += 1
          this.timer = setInterval(() => {
            this.time--;
            this.buttonInfo = '确认签约'+this.time+'S'
            if (this.time <= 0) {
              this.buttonInfo = '确认签约'
              this.disabled = false;
              this.time = 10;
              clearInterval(this.timer);
            }
          }, 1000);
        }
      })
    },

    handleMobileClick() {
      this.$nextTick( ()=> {
        if (this.count === 0) {
          this.count += 1
          this.timer = setInterval(() => {
            this.time--;
            this.buttonInfo = '确认签约'+this.time+'S'
            if (this.time <= 0) {
              this.buttonInfo = '确认签约'
              this.disabled = false;
              this.time = 10;
              clearInterval(this.timer);
            }
          }, 1000);
        }
      })
    },


    handleScroll() {
      this.$nextTick(() => {
        let scrollTop = document.getElementById('el-dialog__wrapper').scrollTop
        if (scrollTop >= 870 && this.count === 0) {
          this.count += 1

          for(let i=0;i<this.time;i++){
            setTimeout(()=>{
              this.time--;
              this.buttonInfo = '确认签约'+this.time+'S'
              console.log("倒计时>"+this.time+"秒");
              if (this.time <= 0) {
                this.buttonInfo = '确认签约'
                this.disabled = false;
                this.time = 10;
              }
            },1000*i)
          }

          // this.timer = setInterval(() => {
          //   this.time--;
          //   this.buttonInfo = '确认签约'+this.time+'S'
          //   if (this.time <= 0) {
          //     this.buttonInfo = '确认签约'
          //     this.disabled = false;
          //     this.time = 10;
          //     clearInterval(this.timer);
          //   }
          // }, 1000);
        }
      })
    },


    showModal() {
      this.dialogVisible = true
      if(this.isMobile) {
        this.handleMobileClick()
      }
    },

    handleClose() {
      this.dialogVisible = false
      this.checked = false
      this.disabled = true
      clearInterval(this.timer)
      this.count = 0
      this.time = 10
      this.buttonInfo = '确认签约10S'
      document.getElementById('el-dialog__wrapper').scrollTop = 0
    },

    closeContranct() {
      this.handleClose()
    },

    contractClick() {
      // this.$refs.useModal.showDialog()
      if(this.checked) {
        this.$refs.useModal.showDialog()
      } else {
        this.$message.warning('请勾选协议')
        return
      }
    },
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
    // window.removeEventListener("touchstart", this.handleTouchstart, false);
  },

}
</script>

<style scoped lang="scss">
/deep/ .el-dialog__title {
  color: $colorT1;
  font-weight: 600;
}
.contract-modal {
  /deep/.el-dialog__header {
    text-align: center;
  }
}
p {
  line-height: 20px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-16 {
  margin-bottom: 16px;
}
.title {
  color: $colorT1;
  font-weight: 600;
  margin-bottom: 8px;
}
.updateTime {
  text-align: center;
  font-weight: 600;
  margin-bottom: 12px;
}
.indent{
  text-indent: 2em;
}
.btn-box  {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  .cancel-btn {
    height: 38px;
    background-color: #ffffff;
    color: $colorA;
    border: 1px solid $colorA;
    margin-right: 12px;
  }
  .btn {
    width: 150px;
  }
  .contract-btn {
    color: #ffffff;
    background-color:$colorA;
  }
  .is-disabled {
    background-color: #cccccc;
  }
}
@media only screen and (max-width: 800px) {
  /deep/.el-dialog{
    width: 100% !important;
    // height: 88vh;
    // overflow-y: auto;
    // margin-top: 1vh;
  }
  /deep/ .v-modal{
    z-index: 20;
  }
  .wx-login{
    display: none;
  }
  .card{
    padding: 0;
  }

}
</style>
